
import React, { useState } from 'react';
import { Typography, Card, Col, Row, Image } from 'antd';
import { FormattedMessage } from 'react-intl';

import EmptyImage from './common/emptyImage'

const { Title } = Typography;

const Projects = () => {
  const info = [
    {
      date: '2020年10月 - 2023年2月',
      projectName: '恒生商业 银行 e-banking',
      company: '汇丰银行(中国)有限公司',
      description: '恒生商业e-Banking用户服务,向商业用户提供信贷服务,资金管理,信用卡管理,强积金MPF服务,使用设定等等,属于企业用户内部的系统管理方案',
      note: 'hsbcNote',
      image: require('../asset/hsbc.png')
    },
    {
      date: '2019年08月 - 2020年10月',
      projectName: '广益联募平台',
      company: '广州慈善网（广益联募）',
      description: '广益联募慈善募捐平台是广州市深化“羊城慈善为民”行动创建全国“慈善之城”重点打造的慈善枢纽平台，也是广州地区公益界最具影响力和实力的网络公益平台。广益联募平 台以“联结力量、增进公益”为使命，不断创新慈善“玩法”，将慈善与消费、运动、艺 术、文化等领域的各种慈善力量联结起来、融合发展，让慈善成为社会生活的链接。',
      note: 'gylmNote',
      image: require('../asset/gylm.png')
    },
    {
      date: '2017年02月 - 2019年07月',
      projectName: '流连Travel',
      company: '广州流连策划有限公司',
      description: '旅游项目，商品都是以旅游路线为主。 主打青年社交旅游，吃喝玩乐专题网站。 主要营销旅游路线，邮轮，门票，酒店等业务',
      note: 'liulianNote1',
      image: require('../asset/liulian.png')
    },
    {
      date: '2017年02月 - 2019年08月',
      projectName: 'Yiton商城',
      company: '广州流连策划有限公司',
      description: '项目是一个手机端商城用户，包含各类百货产品，属于流连旗下子公司的一个项目，其中功能有普通用户登录注册和订单，购物车功能等',
      note: 'liulianNote2',
      image: require('../asset/Yiton.png')
    },
    {
      date: '2013年05月 - 2017年02月',
      projectName: '花生居电商项目',
      company: '广州花生居电子商务有限公司',
      description: '项目主营家私、灯具、布艺、饰品四大品类。以研发（设计）、整合产业链及销售为花生居的三大核心。花生居线上运营电子商务平台，线下开设样板体验间，全尺寸全空间的提 供家居整体设计供消费 者体验及了解。',
      note: 'huashengjuNote',
      image: require('../asset/huashengju.png')
    },
    {
      date: '2012年02月 - 2013年04月',
      projectName: '燃情单车项目',
      company: '广州贝易信息科技有限公司',
      description: '贝易信息科技旗下的一个单车项目组,主要功能是单车咨询，报名中心，论坛交流和 CMS功能。',
      note: 'beiyiNote1',
      image: ''
    },
    {
      date: '2010年04月 - 2013年04月',
      projectName: 'BFE企业站',
      company: '广州贝易信息科技有限公司',
      description: '一个跨国电子商务平台，主营产品分为两大类，包括电子科技类、家居用品类，并积极拓 展新产品及新区域市场。是一家有核心竞争力的国际电子商务企业，致力于为全球消费者 提供优质、迅捷、便利的终端零售商品和服务。',
      note: 'beiyiNote2',
      image: ''
    },
  ]

  const [activeTabKey0, setActiveTabKey0] = useState('projectName');
  const [activeTabKey1, setActiveTabKey1] = useState('projectName');
  const [activeTabKey2, setActiveTabKey2] = useState('projectName');
  const [activeTabKey3, setActiveTabKey3] = useState('projectName');
  const [activeTabKey4, setActiveTabKey4] = useState('projectName');
  const [activeTabKey5, setActiveTabKey5] = useState('projectName');
  const [activeTabKey6, setActiveTabKey6] = useState('projectName');

  const stepContent = (index) => {
    return <Row>
      <Col span={24}>
        {info[index]?.description}
        <p></p>
        <FormattedMessage
          id={info[index]?.note}
          values={{
            wrap: <br></br>,
          }}
        />
      </Col>
    </Row >
  }

  const stepContent2 = (index) => {
    return <Row>
      <Col span={24}>
        <Col span={24}>
          {info[index]?.projectName}
          <br></br>
          {info[index]?.company}
        </Col>
        {info[index]?.image ? <Image
          // width={150}
          style={{ maxWidth: '150px' }}
          src={info[index]?.image}
        /> : <EmptyImage />}
      </Col></Row>
  }

  const tabListNoTitle = [
    {
      key: 'projectName',
      tab: '项目名',
    },
    {
      key: 'description',
      tab: '项目详细',
    },
  ];

  const contentListNoTitle = (key, index) => {
    if (key === 'projectName') {
      return stepContent2(index);
    }
    if (key === 'description') {
      return stepContent(index);
    }
  };

  const onTabChange0 = (key) => {
    setActiveTabKey0(key);
  };

  const onTabChange1 = (key) => {
    setActiveTabKey1(key);
  };
  const onTabChange2 = (key) => {
    setActiveTabKey2(key);
  };
  const onTabChange3 = (key) => {
    setActiveTabKey3(key);
  };
  const onTabChange4 = (key) => {
    setActiveTabKey4(key);
  };
  const onTabChange5 = (key) => {
    setActiveTabKey5(key);
  };
  const onTabChange6 = (key) => {
    setActiveTabKey6(key);
  };

  //   return <Row>
  //     <Col span={4}>
  //       <Col span={24}>{info[index]?.company}</Col>
  //       {info[index]?.image ? <Image
  //         width={150}
  //         src={info[index]?.image}
  //       /> : <EmptyImage />}
  //     </Col>
  //     <Col span={20}>
  //       项目描述<br></br>{info[index]?.description}
  //       <p></p>
  //       <FormattedMessage
  //         id={info[index]?.note}
  //         values={{
  //           wrap: <br></br>,
  //         }}
  //       />
  //     </Col>
  //   </Row >
  // }

  return (
    <>
      <Card
        style={{ width: '100%', margin: '0 0 1rem 0' }}
        tabList={tabListNoTitle}
        activeTabKey={activeTabKey0}
        // tabBarExtraContent={<a href="#">More</a>}
        onTabChange={onTabChange0}
      >
        {contentListNoTitle(activeTabKey0, 0)}
      </Card>

      <Card
        style={{ width: '100%', margin: '0 0 1rem 0' }}
        tabList={tabListNoTitle}
        activeTabKey={activeTabKey1}
        // tabBarExtraContent={<a href="#">More</a>}
        onTabChange={onTabChange1}
      >
        {contentListNoTitle(activeTabKey1, 1)}
      </Card>

      <Card
        style={{ width: '100%', margin: '0 0 1rem 0' }}
        tabList={tabListNoTitle}
        activeTabKey={activeTabKey2}
        // tabBarExtraContent={<a href="#">More</a>}
        onTabChange={onTabChange2}
      >
        {contentListNoTitle(activeTabKey2, 2)}
      </Card>

      <Card
        style={{ width: '100%', margin: '0 0 1rem 0' }}
        tabList={tabListNoTitle}
        activeTabKey={activeTabKey3}
        // tabBarExtraContent={<a href="#">More</a>}
        onTabChange={onTabChange3}
      >
        {contentListNoTitle(activeTabKey3, 3)}
      </Card>

      <Card
        style={{ width: '100%', margin: '0 0 1rem 0' }}
        tabList={tabListNoTitle}
        activeTabKey={activeTabKey4}
        // tabBarExtraContent={<a href="#">More</a>}
        onTabChange={onTabChange4}
      >
        {contentListNoTitle(activeTabKey4, 4)}
      </Card>

      <Card
        style={{ width: '100%', margin: '0 0 1rem 0' }}
        tabList={tabListNoTitle}
        activeTabKey={activeTabKey5}
        // tabBarExtraContent={<a href="#">More</a>}
        onTabChange={onTabChange5}
      >
        {contentListNoTitle(activeTabKey5, 5)}
      </Card>

      <Card
        style={{ width: '100%', margin: '0 0 1rem 0' }}
        tabList={tabListNoTitle}
        activeTabKey={activeTabKey6}
        // tabBarExtraContent={<a href="#">More</a>}
        onTabChange={onTabChange6}
      >
        {contentListNoTitle(activeTabKey6, 6)}
      </Card>
    </>
  );
}
export default Projects;
