
const NodeJsSkill = () => {

  return (
    <>
      <p>
        在这里我使用eggJs用来做中间层,使用到中间件和egg-mysql等概念.
      </p>
      <p>
        koa2诞生于express框架，egg基于koa2的洋葱圈衍生出来的架构，基于洋葱圈的原理和强大的生态，所以我这里选用了eggjs作为我的API服务器。并没有选用其他模板引擎，因为前后端分离还是必须的。
      </p>
      <p>
        API demo链接: <a href="https://api.y3iu.com/company/1" target="_blank" rel="noreferrer">https://api.y3iu.com/company/1</a>
      </p>
    </>
  )
}

export default NodeJsSkill;