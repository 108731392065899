const zh_CN = {
  hsbc: '主要负责与BA对接沟通,分析story的AC需求 {wrap} 负责Design UI实践AC的场景业务 {wrap} 负责React project的UI开发 {wrap} 负责React project与Back End的spec对接 {wrap} 使用hooks,react-hook-form,redux,saga,css-in-js等技术盏 {wrap} 负责对接 otp msk 校验系统 {wrap} 负责前端的 accessbility 开发 {wrap} 负责前端 aem project 的开发 {wrap} 支援其他项目开发 {wrap} ',
  gylm: '负责单位现有系统的页面功能优化和维护{wrap}配合后端进行新版本开发和新功能开发{wrap}对接后端API文档进行页面布局渲染工作{wrap}管理前端项目git源码和发布{wrap}',
  liulian: '负责公司的官网页面开发和维护{wrap}负责和产品经理沟通需求和逻辑优化{wrap}负责和后端对接数据并渲染页面{wrap}对接微信登录接口和微信支付{wrap}开发三端（PC，移动，小程序）的页面开发，PC端已停{wrap}优化和兼容不同浏览器和手机的适配{wrap}',
  huashengju: '负责设计官网和移动端商城的设计界面{wrap}负责实现设计和前端的交互实现{wrap}负责官网活动和专题页面的开发{wrap}对接后端api开发画板功能canvas控件{wrap}对接微信登录微信支付和其他第三方接口{wrap}构建前端架构搭建{wrap}',
  beeyi: '负责开发公司项目（含旗下子公司）{wrap}负责公司内部效率类产品的研发，遵照开发规范，按时保质地完成所负责的功能模块{wrap}负责项目需求分析和设计、开发及后期维护{wrap}参与现有项目的架构优化工作{wrap}',
  hsbcNote: '个人职责{wrap}1. 使用React开发e-banking Dsr Relevant Person Update{wrap}2. 使用React开发e-banking otp msk components{wrap}3. 使用React开发e-banking Company Infomation Update {wrap}4. 开发 AEM invement project{wrap}5. 参与 project 核心沟通与对接 backend api{wrap}{wrap}技术选型：react-hooks,react-hook-form,redux,saga,css-in-js,style-compontents',
  gylmNote: '个人职责{wrap}由于这平台初期是第三方外包出去，所以我进去之后我是负责对接外包的源码工作{wrap}1.前期外包 是用 tp3.2模板+jq+swiper+angularjs{wrap}2.后期 使用 wepack+sass+gulp+vue全家桶+nodejs+element-ui{wrap}3.使用flex流式布局制作PC 和移动端页面{wrap}4.使用vue中的全家桶 vue-router,vuex,axios 构建前台page页面{wrap}5.实现页面逻辑和JS交互效果{wrap}6.维护和优化旧页面 和 新页面的运行速度，兼容等问题{wrap}7.对接后台API渲染页面和微信登录支付各逻辑{wrap}8.解决跨域请求的问题{wrap}{wrap}旧版本技术选型：tp3.2模板+jq+swiper+angularjs{wrap}新版本技术选型：wepack+sass+gulp+vue全家桶+nodejs+element-ui+layerUI',
  liulianNote1: '个人职责{wrap}1.负责 wepack+sass+gulp+vue全家桶+nodejs+element-ui+vux+小程序原生{wrap}2.负责对接小程序 微信公众号（服务号） 微信商户平台（微信支付）支付宝的对接工作{wrap}3.开发小程序日期选择控件{wrap}4.使用echarts实现后台数据可视化{wrap}5.审核review下属的代码及时发现BUG和安全隐患{wrap}6.实现系统代码逻辑和需求完成产品的上线和发布工作{wrap}7.实现部分组件函数防抖和数 据缓存{wrap}7.约定前后端JSON结构 ，和命名规则{wrap}{wrap}技术选型：wepack+sass+gulp+vue全家桶+nodejs+element-ui+vux+小程序原生+layerUI',
  liulianNote2: '个人职责{wrap}1.负 责 wepack+sass+gulp+vue全家桶 +nodejs+element-ui+小程序原生{wrap}2.负责对接小程序微信公众号（ 服务号 ）微信商户平台（ 微信支付 ）支付宝的对接工作{wrap}3.使用echarts实现后台数据可视化{wrap}4.设置跨域nginx白名单和设备检测{wrap}5.实现websocket客服对话功能{wrap}{wrap}技术选型：wepack+sass+gulp+vue全家桶+nodejs+element-ui+vux+小程序原生+layerUI{wrap}官网地址：https://web.yiton.net/',
  huashengjuNote: '个人职责{wrap}1.前端自动化构建和部署工作{wrap}2.使用Bootstrap和HTML5+CSS3+requireJs+seaJs+gulp+JQ进行页面开发{wrap}3.使用echarts,three数据可视化插件进行数据渲染工作{wrap}4.使用高德地图接口对地图进行定制功能{wrap}5.使用JQ做页面交互并封装功能{wrap}6.icon使用阿里的ICON云图标{wrap}7.使用canvas开发特色画板功能，其中牵扯到算法勾股定理，贝塞尔曲线等等{wrap}8.使用requireJs实现简单模块化开发{wrap}9.使用gulp压缩混肴JS和CSS代码{wrap}{wrap}官网地址: https// www.huashengju .com/',
  beiyiNote1: '个人职责{wrap}我的责任就是对这个网站进行开发，采用PHPCMS搭建，整合UCENTER，实现文章发布功能和专题项目发布，对该设计图进行布局和开发手机版布局，开发部分机器人采集{wrap}{wrap}官网地址：http://www.zestbike.com',
  beiyiNote2: '个人职责{wrap}负责开发一些模块如登录接口，支付接口，淘宝数据同步接口等{wrap}开发先专题模板，开发插件{wrap}页面设计 DIV+CSS HTML排版{wrap}{wrap}官网地址：http://www.bfewww.com'
}

export default zh_CN