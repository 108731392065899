import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from "redux-saga";

import App from './containers/App';
import { reducer, saga } from "./reducers"
import reportWebVitals from './reportWebVitals';
import Inter from './locale/intl';

const newSaga = createSagaMiddleware()  //创建saga中间件

const store = createStore(reducer, applyMiddleware(newSaga)); //创建sotre

newSaga.run(saga) //使用中间件的run方法将自己手动创建的saga挂中间件上去

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode> 严格模式会把useEffect 执行2次
  // <React.StrictMode> 
  <Provider store={store}>
    <Inter>
      <App />
    </Inter>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
