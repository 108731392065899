
import React from 'react';
import { Steps, Row, Col, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Title } = Typography;

const WorkInfo = () => {
  const info = [
    {
      date: '2020年10月 - 2023年2月',
      company: '汇丰银行(中国)有限公司',
      job: '资深前端开发工程师',
      workInfo: 'hsbc'
    },
    {
      date: '2019年08月 - 2020年10月',
      company: '广州慈善网（广益联募）',
      job: '高级前端开发工程师',
      workInfo: 'gylm'
    },
    {
      date: '2017年02月 - 2019年07月',
      company: '广州流连策划有限公司',
      job: '高级前端架构师',
      workInfo: 'liulian'
    },
    {
      date: '2013年05月 - 2017年02月',
      company: '广州花生居电子商务有限公司',
      job: '高级前端架构师',
      workInfo: 'huashengju'
    },
    {
      date: '2010年04月 - 2013年04月',
      company: '广州贝易信息科技有限公司',
      job: '高级PHP开发工程师',
      workInfo: 'beeyi'
    }
  ]

  const stepContent = (index) => {
    return <Row>
      <Col span={4}>
        {info[index]?.job}
      </Col>
      <Col span={20}>
        <FormattedMessage
          id={info[index]?.workInfo}
          values={{
            wrap: <br></br>,
          }}
        />
      </Col>
    </Row >
  }

  return (
    <>
      <Steps
        progressDot
        current={5}
        direction="vertical"
        items={[
          {
            title: <Title level={5}>{info[0]?.company} - {info[0]?.date}</Title>,
            description: stepContent(0),
          },
          {
            title: <Title level={5}>{info[1]?.company} - {info[1]?.date}</Title>,
            description: stepContent(1),
          },
          {
            title: <Title level={5}>{info[2]?.company} - {info[2]?.date}</Title>,
            description: stepContent(2),
          },
          {
            title: <Title level={5}>{info[3]?.company} - {info[3]?.date}</Title>,
            description: stepContent(3),
          },
          {
            title: <Title level={5}>{info[4]?.company} - {info[4]?.date}</Title>,
            description: stepContent(4),
          },
        ]}
      />
    </>
  );
};

export default WorkInfo;
