
import { createSlice } from '@reduxjs/toolkit';

import { REDUCER_ROOT_KEY, ROOT_KEY } from '../keys';

const initialState = {
  isLoading: false,
  isError: false,
  content: '1',
  newsData: [],
  eggData: {}
};

const slice = createSlice({
  name: `${REDUCER_ROOT_KEY}/${ROOT_KEY}`,
  initialState,
  reducers: {
    setContent: (state, action) => {
      state.content = action.payload;
    },
    getNews: () => { },
    setNews: (state, action) => {
      state.newsData = action.payload?.data;
    },
    getEggData: () => { }
  },
});

export { initialState, slice, ROOT_KEY };
