import axios from 'axios';

axios.interceptors.response.use((response) => {
  // 一般restful规范的接口在这里接收到的都是正确数据，直接返回数据即可
  return response
}, (error) => {
  return Promise.reject(error.message)
})

const httpGet = (params) => {
  // const param = {
  //   params: params.param
  // }
  let param = {};
  if (params.param) {
    param.params = params.param;
  }
  return axios.get(params.url, param).then((result) => {
    return result;
  })
}

export default httpGet