
import React from 'react';
import { Layout, Typography } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { rootReducer } from '../../reducers';
import { menuText } from '../../constants/common';

const { Title } = Typography;

const { Header } = Layout;

const { selectContent } = rootReducer;

const TitleStyle = styled(Title)`
  margin: 1rem 0rem;
`;

const HeaderStyle = styled(Header)`
  margin: 0rem;
  padding-left: 2rem;
  background-color: #fff;
`;

const HeaderTitle = () => {
  const contentVal = useSelector(selectContent)
  return (
    <HeaderStyle>
      <TitleStyle level={3}>{menuText[contentVal - 1]}</TitleStyle>
    </HeaderStyle>
  );
};

export default HeaderTitle;
