/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Divider } from 'antd';
import ThreeSkill from './threeSkill';

const ThreeSkillBox = () => {
  return (
    <>
      <p>
        这是参照贝壳用threeJs实现的3D全屋VR场景,麻烦小手鼠标点击一下白点看效果,可以拖曳
      </p>
      <Divider />
      <div id="threeBox" style={{ margin: '1rem 0 0 0', width: 'auto', height: '500px' }}>
        <ThreeSkill />
      </div>
    </>

  );
}
export default ThreeSkillBox;