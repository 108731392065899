

const ElectronSkill = () => {

  return (
    <>
      <p>
        Electron的作品,win桌面端请下载以下链接
      </p>
      <p>
        <a href="https://down.y3iu.com/electron.exe" target="_blank" rel="noreferrer">electron</a>
      </p>
    </>
  )
}

export default ElectronSkill;