import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import { ROOT_KEY } from './keys';
import * as rootReducer from './root';

const REDUCER_ROOT_KEY = 'Benson';

/**
 * Reducer
 */
const reducer = combineReducers({
  [ROOT_KEY]: rootReducer.slice.reducer,
});

/**
 * Sagas
 */
function* saga() {
  yield all([...rootReducer.saga]);
}

// export { REDUCER_KEY, reducer, saga, rootStore, securityDeviceStore, mobiledevieStore };
export { REDUCER_ROOT_KEY, reducer, saga, rootReducer };
