
import { Image } from 'antd';
const WxSkill = () => {

  return (
    <>
      <p>
        用微信搜索<span style={{ color: 'red' }}>广益联募</span>关键字,这个小程序是用uni-app做的.
      </p>
      <p>
        另一个是流连旅游小程序的作品,目前因为前公司倒闭的缘故，站点已经停了，这套源码是用原生写的，源码尚在可以放出来，但请尊重我的劳动成果不要外传谢谢
      </p>
      <p>
        <Image
          width={150}
          src={require('../../asset/liulian.png')}
        />
      </p>

      <p>
        <a href="https://down.y3iu.com/mini_wx.zip" target="_blank" rel="noreferrer">源码</a>
      </p>
    </>
  )
}

export default WxSkill;