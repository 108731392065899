

const ReactNativeSkill = () => {

  return (
    <>
      <p>
        react native的作品,因为本人没有mac book，只能做安卓版本，IOS需要mac xcode开发
      </p>
      <p>
        <a href="https://down.y3iu.com/app-release.apk" target="_blank" rel="noreferrer">安卓版本</a>
      </p>
    </>
  )
}

export default ReactNativeSkill;