
const LinuxSkill = () => {

  return (
    <>
      <p>
        目前的环境使用的是阿里云的服务器，centos7版本
      </p>
      <p>
        Linux常用命令都没问题,docker更是简单，目前nginx和mysql都是docker镜像安装的，本站是最能体现技术深度和广度，请面试大佬高抬贵手给个面试机会谢谢
      </p>
    </>
  )
}

export default LinuxSkill;