
import { Image } from 'antd';
const PhpSkill = () => {

  return (
    <>
      <p>
        <Image
          width={150}
          src={require('../../asset/huashengju.png')}
        />
      </p>
      <p>
        关于PHP早年间曾在花生居做过一段时间后端开发，使用的是ThinkPhp框架，更早期也接触过EcShop，DedeCMS之类的框架
      </p>
      <p>
        关于JAVA我会用spring boot框架,maven等等.
      </p>
      <p>
        而nginx目前个人站点就是使用nginx做反向代理。
      </p>
    </>
  )
}

export default PhpSkill;