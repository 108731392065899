import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, List, Space, Divider } from 'antd';
import { StarOutlined, LikeOutlined, MessageOutlined } from '@ant-design/icons';

import { rootReducer } from '../../reducers';

const {
  slice: {
    actions: {
      getNews,
      getEggData
    },
  },
  selectNewsData
} = rootReducer;

const AxiosSkill = () => {
  const newsData = useSelector(selectNewsData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNews());
    dispatch(getEggData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(newsData, 'newsData');
  }, [newsData]);

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  const data = newsData.map((item, i) => {
    return {
      href: 'https://ant.design',
      title: item?.title,
      avatar: `https://joesch.moe/api/v1/random?key=${i}`,
      description: item?.digest,
      content:
        'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
      imgsrc: item?.imgsrc,
    }
  });

  return (
    <>
      <p>
        前端必备的call api技能,不可能不会的
      </p>
      <Divider />
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: (page) => {
            console.log(page);
          },
          pageSize: 3,
        }}
        dataSource={data}
        renderItem={(item) => {
          return <List.Item
            key={item.title}
            actions={[
              <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
              <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
              <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
            ]}
            extra={
              <img
                width={272}
                alt="logo"
                src={item?.imgsrc}
                style={{ maxHeight: 150 }}
              />
            }
          >
            <List.Item.Meta
              avatar={<Avatar src={item.avatar} />}
              title={<a href={item.href}>{item.title}</a>}
              description={item.description}
            />
            {item.content}
          </List.Item>
        }}
      />
    </>
  )
}

export default AxiosSkill;