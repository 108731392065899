const ReactSkill = () => {

  return (
    <>
      <p>
        目前这个个人网站就是用react + ant design 弄出来的
      </p>
      <p>
        这个react使用的是16以上版本，用的是react hooks函数式组件，常用的有useEffect,useMemo,useCallBack
      </p>
      <p>
        组件之间的通信是用redux+redux-saga中间件做的组件通信和状态管理
      </p>
    </>
  )
}

export default ReactSkill;