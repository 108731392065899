
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu } from 'antd';
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';

import { rootReducer } from '../../reducers';
import { menuText } from '../../constants/common';

const {
  slice: {
    actions: {
      setContent,
    },
  },
  selectContent
} = rootReducer;

const menuList = [
  { iconObj: UserOutlined, title: menuText[0] },
  { iconObj: VideoCameraOutlined, title: menuText[1] },
  { iconObj: UploadOutlined, title: menuText[2] },
  { iconObj: UploadOutlined, title: menuText[3] }
]


const LeftMenu = () => {

  const contentVal = useSelector(selectContent);
  const dispatch = useDispatch();

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[contentVal]}
      items={menuList.map(
        (icon, index) => ({
          key: String(index + 1),
          icon: React.createElement(icon.iconObj),
          label: icon.title,
        }),
      )}
      onClick={(item, key, keyPath) => {
        dispatch(setContent(item.key));
      }}
    />
  );
};

export default LeftMenu;
