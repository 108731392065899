
import React from 'react';
import { Collapse } from 'antd';
import EchartSkill from './skills/echartSkill';
import AxiosSkill from './skills/axiosSkill';
import VueSkill from './skills/vueSkill';
import ThreeSkillBox from './skills/threeSkillBox';
import ReactSkill from './skills/reactSkill';
import ReactNativeSkill from './skills/reactNativeSkill';
import ElectronSkill from './skills/electronSkill';
import WxSkill from './skills/wxSkill';
import WeiFontEndSkill from './skills/weiFontEndSkill';
import NodeJsSkill from './skills/nodeJsSkill';
import PhpSkill from './skills/PhpSkill';
import LinuxSkill from './skills/LinuxSkill';

const { Panel } = Collapse;

const Skill = () => {
  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return (
    <>

      <Collapse defaultActiveKey={['3']} onChange={onChange}>
        <Panel header="threeJs" key="1">
          <ThreeSkillBox />
        </Panel>
        <Panel header="echars" key="2">
          <EchartSkill />
        </Panel>
        <Panel header="react && Ant-design" key="3">
          <ReactSkill />
        </Panel>
        <Panel header="vue2 && vue3" key="4">
          <VueSkill />
        </Panel>
        <Panel header="ajax && axios" key="5">
          <AxiosSkill />
        </Panel>
        <Panel header="react native" key="6">
          <ReactNativeSkill />
        </Panel>
        <Panel header="electron" key="7">
          <ElectronSkill />
        </Panel>
        <Panel header="小程序 && uni-app" key="8">
          <WxSkill />
        </Panel>
        <Panel header="微前端" key="9">
          <WeiFontEndSkill />
        </Panel>
        <Panel header="nodejs && eggjs" key="10">
          <NodeJsSkill />
        </Panel>
        <Panel header="java && php && nginx" key="11">
          <PhpSkill />
        </Panel>
        <Panel header="Linux && docker" key="12">
          <LinuxSkill />
        </Panel>
      </Collapse>
    </>
  );
};

export default Skill;
