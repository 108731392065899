/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import w04 from '../../asset/three/RESULT/w04.png'
// import w05 from '../../asset/three/RESULT/w05.png'
import w06 from '../../asset/three/RESULT/w06.png'
import w02 from '../../asset/three/RESULT/w02.png'
import w01 from '../../asset/three/RESULT/w01.png'
import w03 from '../../asset/three/RESULT/w03.png'
import home1_left from '../../asset/three/img/home1_left.jpg'
import home1_right from '../../asset/three/img/home1_right.jpg'
import home1_top from '../../asset/three/img/home1_top.jpg'
import home1_bottom from '../../asset/three/img/home1_bottom.jpg'
import home1_front from '../../asset/three/img/home1_front.jpg'
import home1_back from '../../asset/three/img/home1_back.jpg'

import home2_left from '../../asset/three/img/home2_left.jpg'
import home2_right from '../../asset/three/img/home2_right.jpg'
import home2_top from '../../asset/three/img/home2_top.jpg'
import home2_bottom from '../../asset/three/img/home2_bottom.jpg'
import home2_front from '../../asset/three/img/home2_front.jpg'
import home2_back from '../../asset/three/img/home2_back.jpg'

import shy1 from '../../asset/three/shy/qian.png'
import shy2 from '../../asset/three/shy/hou.png'
import shy3 from '../../asset/three/shy/left.png'
import shy4 from '../../asset/three/shy/right.png'
import shy5 from '../../asset/three/shy/top.png'
import shy6 from '../../asset/three/shy/bottom.png'

let scene: any = null //场景
let loader: any = null
let planeGemetry: any = null // 跳转点
let planeMaterial: any = null// 跳转点

const ThreeSkill = (): ReactElement => {
  const ref = useRef(null)

  const [camera, setCamera] = useState() //摄像机

  const [renderer, setRenderer] = useState() //渲染器

  const [controls, setControls] = useState() //鼠标控制

  const [homeArr, setHomeArr] = useState([]) // 渲染的数据源

  const [currentHome, setCurrentHome] = useState('') // 当前所处房间

  const [clearPre, setClearPre] = useState('') // 记录需要清理的房间的坐标点

  const [res, setRes] = useState({
    home1: {
      image: [home1_left, home1_right, home1_top, home1_bottom, home1_front, home1_back],// 左右前后上下
      target: [
        { name: 'home2', position: [-16, -8, -9] }
      ]
    },
    home2: {
      image: [home2_left, home2_right, home2_top, home2_bottom, home2_front, home2_back],
      target: [
        { name: 'home1', position: [18, -8, -7] },
        { name: 'home3', position: [2, -8, -7] },
      ]
    },
    home3: {
      image: [w04, 'https://s1.xptou.com/2022/10/14/6348c159dbfc2.jpg', w06, w02, w01, w03],
      target: [
        { name: 'home2', position: [-16, -8, -9] },
        { name: 'home4', position: [-5, -20, -18] }
      ]
    },
    home4: {
      image: [shy3, shy4, shy5, shy6, shy1, shy2,],
      target: [
        { name: 'home1', position: [18, -8, -7] },
        { name: 'home3', position: [-7, -18, -18] }
      ]
    },
  })

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (camera && renderer) {
      setControls(new OrbitControls(camera, renderer.domElement))
    }
  }, [camera, renderer])

  useEffect(() => {
    if (controls) {
      initBaseFactor()
    }
  }, [controls])

  useEffect(() => {
    if (currentHome) {
      if (camera && renderer && controls) {
        initHome();
      }
    }
  }, [currentHome])

  // 设置跳转点的偏移
  const getRotate = (arr: [number, number, number]) => {
    const index = arr.indexOf(Math.max(...arr))
    switch (index) {
      case 0:
        return 'rotateX';
      case 1:
        return 'rotateY';
      case 2:
        return 'rotateZ';
      default:
        return 'rotateX'
    }
  }

  // 处理数据
  const getNewData = (obj: any) => {
    return Object.entries(obj).map((v: any) => {
      return [v[0], {
        ...v[1], image: v[1].image.map((el: any) => {
          const text = loader.load(el)
          return new THREE.MeshBasicMaterial({ map: text, side: THREE.DoubleSide, })
        })
      }]
    })
  }

  const init = () => {
    loader = new THREE.TextureLoader()
    const width = document.getElementById('threeBox').offsetWidth;
    const height = document.getElementById('threeBox').offsetHeight;
    setHomeArr(getNewData(res));
    setCurrentHome(getNewData(res)[0][0]) // 首次进来的房间
    setClearPre(getNewData(res)[0][0])
    setCamera(new THREE.PerspectiveCamera(90, width / height, 0.1, 1000))    // 初始化相机
    setRenderer(new THREE.WebGLRenderer())   // 初始化渲染器
  }

  const initBaseFactor = () => {
    console.log()
    const width = document.getElementById('threeBox').offsetWidth;
    const height = document.getElementById('threeBox').offsetHeight;
    scene = new THREE.Scene();
    // 创建聚光灯
    const spotLight = new THREE.SpotLight(0xFFFFFF);
    spotLight.position.set(80, 130, -130);
    spotLight.castShadow = true;
    spotLight.angle = Math.PI / 4;
    spotLight.shadow.penumbra = 0.05
    spotLight.shadow.mapSize.width = 1024;
    spotLight.shadow.mapSize.innerHeight = 1024;
    // 添加聚光灯
    scene.add(spotLight)
    camera.position.x = 0;
    camera.position.y = 0;
    camera.position.z = -0.4;
    renderer.setSize(width, height);
    renderer.setClearColor(new THREE.Color("#dddddd"));
    document.getElementById("threeDemo").appendChild(renderer.domElement);
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    function onMouseDown(event: any) {
      // 会产生闭包
      const isLeftClick = event.button === 0 //鼠标左键

      const xValue = document.getElementById('threeBox').offsetLeft;
      const yValue = document.getElementById('threeBox').offsetTop;
      console.log(xValue, yValue);
      mouse.x = ((event.clientX - xValue) / width) * 2 - 1;
      mouse.y = -((event.clientY - yValue) / height) * 2 + 1;
      //将平面坐标系转为世界坐标系
      raycaster.setFromCamera(mouse, camera);

      //得到点击的几何体
      const raycasters = raycaster.intersectObjects(scene.children);
      if (raycasters && raycasters.length && raycasters.some(v => v.object.name.includes('locationBtn'))) {
        const to = raycasters.find(el => el.object.name.includes('locationBtn'))
        if (isLeftClick) {
          // 鼠标左键点击切换场景
          setCurrentHome((v) => {
            setClearPre(v)
            return to?.object.name.split('-')[1] || ''
          })
        }
      }
    }

    //监视鼠标事件
    document.getElementById('threeBox').addEventListener("mousedown", onMouseDown, false);
    // 创建controls对象;
    controls.enableDamping = true; //动态阻尼系数 就是鼠标拖拽旋转灵敏度
    controls.minDistance = 0.01;
    controls.maxDistance = 20;
    // 监听控制器的鼠标事件，执行渲染内容
    controls.addEventListener('change', () => {
      renderer.render(scene, camera)
    })
    initHome();
    renderHome();
  }


  const initHome = () => {
    // 切换场景前把之前的物体清除掉
    const homeMesh1 = scene.getObjectByName('homeMesh')
    homeArr.find((v: any) => clearPre === v[0])[1]?.target?.map(((el: any) => el.name))?.forEach((v: string) => {
      scene.remove(scene.getObjectByName(`locationBtn-${v}-${clearPre}`))
    })
    scene.remove(homeMesh1)
    const activeHome = homeArr.find((v: any) => v[0] === currentHome)
    // 创建一个矩形，贴上六张材质图片，模拟室内效果
    const homeGeoMetry = new THREE.BoxGeometry(100, 100, 100);
    const homeMesh = new THREE.Mesh(homeGeoMetry, activeHome[1].image);
    homeMesh.castShadow = true
    homeMesh.position.set(0, 0, 0);
    homeMesh.geometry.scale(1, 1, -1);
    homeMesh.name = "homeMesh"
    scene.add(homeMesh);

    // 添加一个圆形按钮，点击后跳转到其他房间场景
    planeGemetry = new THREE.CircleGeometry(1.2, 20);
    planeMaterial = new THREE.LineBasicMaterial({ color: 0xdddddd, side: THREE.DoubleSide });

    console.log(activeHome)
    // const font = new THREE.TextGeometry('miaoshu ')
    activeHome[1].target.forEach((v: any) => {
      const planeMesh = new THREE.Mesh(planeGemetry, planeMaterial);
      const position: [number, number, number] = v.position
      planeMesh.position.set(...position)
      planeMesh[getRotate(position)](0.5 * Math.PI) //调整位置

      planeMesh.name = `locationBtn-${v.name}-${currentHome}`
      scene.add(planeMesh);
    })
  }

  const renderHome = () => {
    requestAnimationFrame(renderHome);
    renderer.render(scene, camera);
  }

  return (
    <div id="threeDemo" ref={ref}></div>
  );
}
export default ThreeSkill;