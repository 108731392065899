import { Divider } from 'antd';
// import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';

const EchartSkill = () => {
  const getOption = () => {
    let option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: [120, 200, 150, 80, 70, 110, 200, 150, 80, 70, 110, 130],
        type: 'bar'
      }]
    };
    return option;
  };

  const getOption2 = () => {
    return {
      title: {
        text: '饼型图',
        subtext: '',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        left: 'left'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: '50%',
          data: [
            { value: 1048, name: 'javascript' },
            { value: 735, name: 'vue' },
            { value: 580, name: 'react' },
            { value: 484, name: 'react native' },
            { value: 300, name: 'electron' }
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
  }

  const getOption3 = () => {
    const option = {
      title: {
        text: '六维图'
      },
      legend: {
        data: []
      },
      radar: {
        // shape: 'circle',
        indicator: [
          { name: 'React', max: 6500 },
          { name: 'Vue', max: 16000 },
          { name: 'Angular', max: 30000 },
          { name: 'javascript', max: 38000 },
          { name: 'php', max: 52000 },
          { name: 'mysql', max: 25000 }
        ]
      },
      series: [
        {
          name: 'Budget vs spending',
          type: 'radar',
          data: [
            {
              value: [4200, 3000, 20000, 35000, 50000, 18000],
              name: 'Benson'
            },
            {
              value: [5000, 14000, 28000, 26000, 42000, 21000],
              name: 'Tom'
            }
          ]
        }
      ]
    };
    return option;
  }

  return (
    <>
      <p>
        这是用echarts实现的数据展示图,类型有挺多的,暂时展示3种不同的demo
      </p>
      <Divider />
      <ReactEcharts option={getOption()} style={{ margin: '0 0 1rem' }} />
      <ReactEcharts option={getOption2()} style={{ margin: '0 0 1rem' }} />
      <ReactEcharts option={getOption3()} style={{ margin: '0 0 1rem' }} />
    </>
  )
}

export default EchartSkill;