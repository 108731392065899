
import React from 'react';
import { Layout } from 'antd';

import { useSelector } from 'react-redux';

import '../style/App.css';
import HeaderTitle from '../conponents/common/headerTitle';
import LeftMenu from '../conponents/common/leftMenu';
import { rootReducer } from '../reducers';
import UserInfo from '../conponents/userInfo';
import WorkInfo from '../conponents/workInfo';
import Projects from '../conponents/projects';
import Skill from '../conponents/skill';


const {
  selectContent
} = rootReducer;

const { Content, Footer, Sider } = Layout;

const App = () => {
  const contentVal = useSelector(selectContent);

  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <LeftMenu />
      </Sider>
      <Layout>
        <HeaderTitle />
        <Content style={{ margin: '24px 16px 0' }}>
          <div style={{ padding: 24, minHeight: 360 }}>
            {contentVal === '1' && <UserInfo />}
            {contentVal === '2' && <WorkInfo />}
            {contentVal === '3' && <Projects />}
            {contentVal === '4' && <Skill />}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Ant Design ©2023 Created by Ant UED</Footer>
      </Layout>
    </Layout>
  );
};

export default App;
