
import React from 'react';
import { Divider, Descriptions, Row, Col, } from 'antd';


const UserInfo = () => {

  return (
    <>
      <Divider orientation="left" orientationMargin="0">基本信息</Divider>
      <Descriptions>
        <Descriptions.Item label="姓名">陈智斌</Descriptions.Item>
        <Descriptions.Item label="性别">男</Descriptions.Item>
        <Descriptions.Item label="工龄">13年工作经验</Descriptions.Item>
        <Descriptions.Item label="当前工作城市">广州</Descriptions.Item>
        <Descriptions.Item label="电话">136-3145-2213</Descriptions.Item>
        <Descriptions.Item label="邮箱">
          307284772@qq.com
        </Descriptions.Item>
      </Descriptions>

      <Divider orientation="left" orientationMargin="0">求职意向</Divider>
      <Descriptions>
        <Descriptions.Item label="求职状态">离职，可一个月内到岗</Descriptions.Item>
        <Descriptions.Item label="期望地点">深圳,广州</Descriptions.Item>
        <Descriptions.Item label="期望职业">web前端开发工程师</Descriptions.Item>
        <Descriptions.Item label="期望月薪">面议</Descriptions.Item>
        <Descriptions.Item label="学历">本科</Descriptions.Item>
      </Descriptions>

      <Divider orientation="left" orientationMargin="0">教育经历</Divider>
      <Row>
        <Col span={6}>
          2022年03月 - 2024年07月
        </Col>
        <Col span={6}>
          广东开放大学
        </Col>
        <Col span={6}>
          计算机科学与技术
        </Col>
        <Col span={6}>
          本科
        </Col>
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <Col span={6}>
          2005年09月 - 2008年07月
        </Col>
        <Col span={6}>
          中山大学
        </Col>
        <Col span={6}>
          旅游管理
        </Col>
        <Col span={6}>
          大专
        </Col>
      </Row >
    </>
  );
};

export default UserInfo;
