
const WeiFontEndSkill = () => {

  return (
    <>
      <p>
        微前端我是用qiankun做架构组织，能做到多技术框架合并成一个集合例如react + vue + angular,解耦并减少包的大小,可以把ui,unit,axios,和逻辑模块单独抽出,能共享common的store状态通信
      </p>
      <p>
        基站应用地址：<a href="https://wei1.y3iu.com" target="_blank" rel="noreferrer">wei1.y3iu.com</a>（react18）
      </p>
      <p>
        子站独立访问：<a href="https://wei2.y3iu.com" target="_blank" rel="noreferrer">wei2.y3iu.com</a>（react18）
      </p>
      <p>
        子站独立访问：<a href="https://wei3.y3iu.com" target="_blank" rel="noreferrer">wei3.y3iu.com</a> （react18）
      </p>
      <p>

      </p>
    </>
  )
}

export default WeiFontEndSkill;