
import { Image } from 'antd';
const VueSkill = () => {

  return (
    <>
      <p>
        Vue2的作品详细请到我以前的项目上观看，但很多基本现在不是停掉了就是不能公开的后台系统。
      </p>
      <p><Image
        width={150}
        src={require('../../asset/Yiton.png')}
      /></p>
      <p>
        Vue2作品-Yiton商城链接：<a href="http://web.yiton.net" target="_blank" rel="noreferrer">http://web.yiton.net</a>
      </p>
      <p>
        为了与其他项目区分开来，以下使用了Vue3 + Element Plus 辨识度较高的框架(比较丑的一款框架)
      </p>
      <p>
        <a href="https://vue3.y3iu.com" target="_blank" rel="noreferrer">https://vue3.y3iu.com</a>
      </p>
    </>
  )
}

export default VueSkill;